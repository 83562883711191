<template></template>

<script>
export default {
  name: "student",
};
</script>

<style scoped>
.v-rating {
  display: flex !important;
}
</style>
